import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import GenericLayout from '../components/PagesLayout/generic';

const NotFoundLayout = (props) => {
  console.log(props);

  useEffect(() => {
    if (props?.location?.pathname.includes('/fr/fr/')) {
      const reworkedUrl = props?.location?.pathname.replace('/fr', '');

      navigate(reworkedUrl);
    }
  }, []);

  return <GenericLayout {...props} />;
};

NotFoundLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NotFoundLayout;

export const query = graphql`
         query NotFoundQuery($locale: String!) {
           content: allPrismic404(filter: { lang: { eq: $locale } }) {
             nodes {
               data {
                 title {
                   text
                 }
                 image {
                   localFile {
                     childImageSharp {
                       fluid(toFormat: JPG) {
                         ...GatsbyImageSharpFluid_noBase64
                       }
                     }
                   }
                 }
                 body {
                   html
                 }
               }
             }
           }
         }
       `;
